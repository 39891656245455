@tailwind base;
@tailwind components;
@tailwind utilities;

*{
  margin:0;
  padding:0;
  font-family: 'Poppins', sans-serif;
  box-sizing: border-box;
}
/* Hide the YouTube logo */


